<template>
  <div class="register-select app_content">
    <header-ele ></header-ele>
    <div class="warp_body">
      <div class="registerSelect" v-for="item in userTypeList ">
        <van-button round block type="primary" @click="chooseRegisterType(item)" :icon="item.icon">
          {{ item.name }}
        </van-button>
      </div>
    </div>

  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import HeaderEle from '../components/common/headerEle'
import API from "../api/User";
import req from "../request/request";
import router from "../router";


export default {
  name: 'RegisterSelect',
  components: {HeaderEle},

  setup() {
    onBeforeMount(() => {
      methodsMap.getUserType()
    });
    const data = reactive({
          images: [
            'https://img.yzcdn.cn/vant/cat.jpeg',
            'https://img.yzcdn.cn/vant/cat.jpeg',
          ],
          userTypeList: []
        },
    )

    const methodsMap = {
      getUserType: () => {
        req.request(API.userType, null, {method: 'GET'}).then((res) => {
          data.userTypeList = res.data.data
        })
      },
      chooseRegisterType: (item) => {
        sessionStorage.setItem("registerType", JSON.stringify(item))
        router.push(item.route)
      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {

    .registerSelect {
      width: 90%;
      margin: 40px auto;
    }

    .protocol {
      text-align: center;
      font-size: 10px;
      color: blue;
    }
  }
}
</style>
